var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { now, toDateString } from "@common/date";
import { login } from "./Login";
// 用來快取表單資料的靜態變數
let cache = null;
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = {
            username: "",
            otp: "",
            password: "",
            confirmPassword: "",
            name: "",
            gender: "",
            birthday: null,
            terms: false,
        };
    }
    onOtp() {
        return __awaiter(this, void 0, void 0, function* () {
            if (yield this.$refs.phone.valid())
                return this.data.username;
            return undefined;
        });
    }
    beforeMount() {
        // 還原表單並且清除快取
        if (cache)
            this.data = cache;
        cache = null;
        if (this.username)
            this.data.username = this.username;
        let date = now();
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        date.setFullYear(date.getFullYear() - 20);
        date.setMonth(0, 1);
        this.init = date;
        this.max = toDateString(now());
    }
    beforeRouteLeave(to, from, next) {
        // 只有當即將前往條款頁面的時候才記憶資料
        if (to.path == '/login/terms')
            cache = this.data;
        next();
    }
    send() {
        return __awaiter(this, void 0, void 0, function* () {
            let result = yield post('/api/login/register', {
                Username: this.data.username,
                Password: this.data.password,
                Gender: this.data.gender,
                OTP: this.data.otp,
                Birthday: this.data.birthday,
                name: this.data.name,
            }, 15000);
            login(result, this.data.username);
            yield this.alert('註冊成功', undefined, 'success');
            this.$router.replace('/');
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop(String)
], default_1.prototype, "username", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
